.media {
	height: 200px;
}

.container {
	padding: 1em;
	display: flex;
	flex-direction: row;
}

.action-area {
	border-radius: 16;
	transition: 0.2s;
} 
.action-area:hover {
	transform: scale(1.1);
}

.card {
	min-width: 256;
	border-radius: 16;
	box-shadow: none;
} 

.card-text{
	text-decoration: none;
}

.card:hover {
	box-shadow: 0 6px 12px 0;
}