.swiper-container {
  width: 100%;
  max-height: 800px;
}

.productsButton {
  display: none;
}

@media screen and (max-width: 768px) {
  .productsButton {
    display: block;
    padding-top: 1em;
  }
}