.NavbarItems {
  background: linear-gradient(135deg, #1CB5E0 0%, #000851 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: white;
  justify-self: start;
  margin-left: 20px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
}

.navbar-logo img {
  margin: auto;
  padding-right: 5px;
  max-width: 64px;
  max-height: 64px;
}

.fa-city {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #1483B7;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: white;
}

.nav-links-mobile {
  display: inline;
}

.menu-icon {
  display: none;
}

.nav-links-mobile {
  text-align: center;
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 4px;
  width: 80px;
  background: #1CB5E0;
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
}

.nav-links-mobile:hover {
  background: white;
  color: #1CB5E0;
  transition: 250ms;
}

@media screen and (max-width: 768px) {
  .fa-bars {
    transition: all 0.5s ease-in-out;
  }

  .fa-times {
    color: white;
    transform: rotate(90deg);
    transition: all 0.5s ease-in-out;
  }
  
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin: auto;
  }

  .nav-menu.active {
    background: #1CB5E0;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: auto;
    padding: 0%;
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1aabd3;
    border-radius: 4px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
  }
}